<script>

/* COMPOSANTS */

/* MIXINS */

/* SERVICES */
import docService from "@services/docService";
import AkFormView from "@components/layout/AkFormView.vue";

export default {
  components: {AkFormView},
  mixins: [],

  metaInfo() {
    return {
      title: "help",
    }
  },
  data() {
    return {
      isDownloading: false,
    }
  },
  mounted() {
  },
  methods: {
    download() {
      this.isDownloading = true;
      docService.download().then(() => {
        this.isDownloading = false;
      });
    },
  },
}
</script>

<template>
  <AkFormView title="Aide" :display-go-back="false">
    <template #action>
      <button @click="download()" class="btn btn-inverse-primary">
        <i class="fe fe-download pr-1" v-if="!isDownloading"/>
        <i class="pi pi-spin pi-spinner mr-1" v-if="isDownloading"/>
        Télécharger la FAQ
      </button>
    </template>

    <template #form>
      <h2 style="color: #013f7a" class="text-center">Foire aux questions</h2>
      <p class="text-center mb-4">Vous pouvez télécharger la FAQ en cliquant sur le bouton ci-dessus.</p>

      <div>
        <h4 style="color: #013f7a">Comment modifier son mot de passe ?</h4>
        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 1</h5>
          <div class="d-flex flex-row">
            <span>Cliquer sur « mot de passe oublié »</span>
          </div>
          <img src="/help/mot_passe_Etape%201.jpg" alt="Image clique sur mot de passe" class="img-fluid"/>
        </div>

        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 2</h5>
          <span>Se connecter avec le mot de passe provisoire fourni par <a
              style="color: dodgerblue; text-decoration: underline dodgerblue;" href="mailto:noreply@akelio.fr">noreply@akelio.fr</a>.</span>
          <br>
          <span>Attention, le mot de passe provisoire est valable 1h00 à partir de la réception du mail.</span>
        </div>


        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 3</h5>
          <div class="d-flex flex-row">
            <img src="/help/mot_passe_Etape%203.jpg" alt="Image changement mot de passe" class="img-fluid"/>
          </div>
        </div>
      </div>

      <div>
        <h4 style="color: #013f7a">Comment puis-je exporter uniquement mes fiches d’intervention ?</h4>
        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 1</h5>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-3">Cliquer sur « Afficher les interventions de l'utilisateur ».</span>
            <img src="/help/exporter_intervention_etape%201.jpg" alt="Image afficher les interventions de l'utilisateur" class="img-fluid"/>
          </div>
        </div>

        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 2</h5>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-3">Cliquer sur le bouton « Personnaliser export fiches » puis sélectionner et valider vos critères de sélection.</span>
            <img src="/help/exporter_intervention_etape%202.jpg" alt="Image personnaliser export fiches"
                 class="img-fluid"/>
          </div>
        </div>

        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 3</h5>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-3">Cliquer sur le bouton « Télécharger fiches personnalisées ».</span>
            <img src="/help/exporter_intervention_etape%203-1.jpg" alt="Image télécharger fiches personnalisées"
                 class="img-fluid"/>
          </div>
          <img src="/help/exporter_intervention_etape%203-2.jpg" alt="Image résumé explications" class="img-fluid"/>
        </div>
      </div>

      <div>
        <h4 style="color: #013f7a">Comment personnaliser le tableau listant les fiches d’intervention et exporter mon listing personnalisé ?</h4>
        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 1 – Personnaliser le tableau listant les fiches d’intervention : à partir de filtres de sélection</h5>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-3">Sélectionner à partir des filtres les informations désirées.</span>
            <img src="" alt="Image filtrage des informations désirées" class="img-fluid"/>
          </div>
        </div>

        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 2 - Personnaliser les paramètres d’affichage de la liste des interventions</h5>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-3">A partir du bouton « Paramétrages d’affichage de la liste d’interventions », choisir vos critères de sélection puis valider</span>
            <img src="" alt="Image bouton paramètres d'affichage de la liste d'interventions" class="img-fluid"/>
          </div>
          <img src="" alt="Image popup paramètres d'affichage de la liste d'interventions" class="img-fluid"/>

          <p class="mt-2">
            Le tableau se met automatiquement à jour en fonction de vos filtres de sélection.
            <br>
            <br>
            Les exports prennent en compte les filtres actuellement appliqués. Pour affiner la
            personnalisation de votre liste à exporter, poursuivre avec <span style="color: dodgerblue">l’étape 3</span> sinon passer directement
            à <span style="color: dodgerblue">l’étape 4</span>.
            <br>
            <br>
            Vous pouvez réinitialiser le tableau d’affichage en cliquant sur le bouton « Réinitialiser les filtres ».
            <img src="/help/personnaliser_tableau_etape%202-3.jpg" alt="Image bouton réinitialiser les filtres" class="img-fluid"/>
          </p>
        </div>

        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 3 – Personnaliser les paramètres d’export des interventions</h5>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-3">Cliquer sur le bouton « Télécharger fiches personnalisées » et choisir les critères de sélection puis valider</span>
            <img src="" alt="Image bouton personnaliser export fiches" class="img-fluid"/>
          </div>
          <img src="/help/personnaliser_tableau_etape%203.jpg" alt="Image popup paramétrage export intervention" class="img-fluid"/>
        </div>

        <div class="mb-4">
          <h5 style="color: #0266c1">Etape 4 – Télécharger la liste personnalisée des interventions</h5>
          <div class="d-flex flex-row align-items-center">
            <span class="mr-3">Cliquer sur le bouton « Télécharger fiches personnalisées »</span>
            <img src="/help/personnaliser_tableau_etape%204-1.jpg" alt="Image bouton télécharger fiches personnalisées" class="img-fluid"/>
          </div>
          <img src="/help/personnaliser_tableau_etape%204-2.jpg" alt="Image résumé paramétrage tableau" class="img-fluid"/>
        </div>
      </div>

    </template>
  </AkFormView>
</template>